<template>
    <div >
        <v-dialog v-model="modifyAcountDialog" width="500px" max-width="400px" style="height: 400px" transition="dialog-transition">
            <v-card>
                <v-card-text>
                <v-toolbar color="warning">
                    <v-toolbar-title style="margin-left:15%;font-size:30px">
                    アカウント設定
                    </v-toolbar-title>
                        
                </v-toolbar>

                <v-card flat>
                    <v-text-field
                        readonly
                        style="margin-top:4%" 
                        :rules="rules"  
                        label="Username" 
                        small 
                        outlined 
                        dense
                        v-model="this.account.username">
                    </v-text-field>
                    <v-text-field  
                        :rules="rules"  
                        readonly
                        label="Current Password"       
                        class="mt-n2" 
                        small 
                        outlined 
                        dense
                        v-model="this.account.password">
                    </v-text-field>
                    <v-text-field 
                        prepend-inner-icon="mdi-lock" 
                        :rules="rules"  
                        clearable    
                        label="New Password"        
                        class="mt-n2" 
                        small 
                        outlined 
                        dense
                        v-model="newPassword">
                    </v-text-field>
                    <v-text-field
                        prepend-inner-icon="mdi-lock"   
                        :rules="rules"  
                        clearable    
                        label="Confirm Password"        
                        class="mt-n2" 
                        small 
                        outlined 
                        dense
                        v-model="confirmPassword">
                    </v-text-field>
                </v-card>
                
                <center>
                    <v-card  flat>
                        <!-- NOTE: You'll be logged out after changing your password. Remember your new one -->
                        <span class="note">注: </span><span>パスワードを変更するとログアウトされます。新しいパスワードを覚えておいてください</span> 
                    </v-card>
                        <v-btn rounded color="warning" @click="updateSubconAccount()">保存</v-btn>
                        <!-- Save Changes -->
                </center>
                </v-card-text>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import { bus } from '../main';
import { message } from "ant-design-vue";
import axios from 'axios';
import Swal from 'sweetalert2';
    export default {
        data : () => ({
            confirmPassword:'',
            newPassword:'',
            modifyAcountDialog : false,
            rules: [(v) => !!v || "Field is required"],
        }),
        mounted () {
            bus.$on('modifyAcountDialog',()=>{
                    this.modifyAcountDialog = true
            });
        },
        methods: {
            updateSubconAccount() {
                    if(this.newPassword === this.account.password && this.confirmPassword === this.account.password){
                        message.warning({
                                bottom: '100px',
                                duration: 3,
                                maxCount: 3,
                                // Your new password must be different from your previous password. Please try again
                                text:'新しいパスワードは以前のパスワードと異なる必要があります。もう一度お試しください。',
                                })
                    }else if(this.newPassword != this.confirmPassword){
                        message.error({
                                bottom: '100px',
                                duration: 3,
                                maxCount: 3,
                                // The passwords don't match. Please try again
                                text:'パスワードが一致しません。もう一度お試しください。',
                                })
                    }else {
                        let updatedSubconUserData = { 
                            username : this.account.username,
                            password : this.newPassword
                        }
                            Swal.fire({
                                title:"アカウントのパスワードを変更する?",
                                // title: "Change account password?",
                                showCancelButton: true,
                                confirmButtonColor: '#3085d6',
                                cancelButtonColor: '#d33',
                                cancelButtonText:'キャンセル',
                                confirmButtonText: 'はい'
                            }).then((result) => { 
                                if (result.isConfirmed) {
                                    axios({
                                        method:'post',
                                        url:`${this.$BaseUrl}/api/updateSubconUserPassword`,
                                        data:updatedSubconUserData,
                                        headers: {
                                                            'x-api-key' : this.awsHeaders
                                                        }
                                    }).then(()=>{
                                        message.success({
                                            bottom: '100px',
                                            duration: 2,
                                            maxCount: 2,
                                            // text:'Account successfully updated!',
                                            text:`アカウントの更新が完了しました！`
                                            })
                                            setTimeout(() => {
                                                message.warning({
                                                    bottom: '100px',
                                                    duration: 2,
                                                    maxCount: 2,
                                                    text:'アカウントはログアウトされます。'
                                                    // text:'The account is being logged out.',
                                                    })
                                                    let updateStatus = { username : this.account.username, status : 0}
                                                            axios({
                                                                    method:'post',
                                                                    url:`${this.$BaseUrl}/api/updateSubconActiveUser`,
                                                                    data:updateStatus,
                                                                    headers: {
                                                                                        'x-api-key' : this.awsHeaders
                                                                                    }
                                                                }) 
                                                    this.closeDialog()
                                                    this.$store.commit("EMPTY_USER");
                                                    this.$router.push("/Login")    
                                            }, 2000);
                                          
                                            
                                    })
                                    
                                }
                            })
                    }
                    
                  

                
            },

            closeDialog(){
                this.newPassword = ''
                this.confirmPassword = ''
                this.modifyAcountDialog = false
            }
        },
    }
</script>

<style scoped>
    .note{
        color:red;
        font-weight: bold;
        justify-content: center;
    }
</style>